<template>
  <div class="search-container">
    <my-header @setData="setData" :isSenior.sync="isSenior" />
    <div class="content">
      <div class="right" v-loading="loading">
        <div v-if="seniorList.length">
          <div
            class="item"
            v-for="v in seniorList"
            :key="v.id"
            @click="onClick(v)"
          >
            <div class="top" v-html="v.title"></div>
            <!-- <div class="middle" v-html="v.sourceDocument"></div> -->
            <div class="middle" v-html="v.content"></div>
          </div>
          <div class="paging">
            <el-pagination
              :page-size="PageSize"
              @current-change="onCurrentChange"
              :current-page.sync="PageIndex"
              :hide-on-single-page="true"
              background
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <div class="empty" style="margin-top: 200px" v-else>
          <el-empty
            :image="require('../../assets/image/empty.png')"
            description="暂无数据"
          ></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>
      
  <script>
import { onSearch } from "@/api/literature.js";
import myHeader from "@/components/header/index.vue";
export default {
  components: {
    myHeader,
  },
  data() {
    return {
      loading: false,
      PageIndex: 1,
      PageSize: 10,
      total: 0,
      seniorList: [],
      cityName: "",
      select: "",
      keyword: "",
      isSenior: false,
      requestData: {},
      version: "",
    };
  },
  created() {
    this.select = this.$route.query.selectType;
    this.keyword = this.$route.query.keyword;
    this.version = this.$route.query.version;
    this.isSenior = this.$route.query.isSenior ? true : false;
    if (this.$route.query.isSenior === "true") {
      this.requestData = this.$store.state.user.seniorData;
    }
    this.onSearch();
  },
  methods: {
    onClick(e) {
      let path = "";
      let title = "";
      switch (e.indexType) {
        case "135":
        case "132":
          path = "/ebook";
          break;
        case "131":
          path = "/Result";
          break;
        case "133":
          path = "/genealogyInfo";
          break;
        case "120":
          path = "/ebook";
          break;
        case "110":
          path = "/overRes";
          if (e.showTitle) {
            title = e.showTitle;
          } else {
            title = e.title;
          }
          break;
        case "200":
          path = "/overBook";
        case "2":
          path = `/tree/${e.sourceID}`;
        default:
          break;
      }
      let routeData = null;
      if (e.indexType === "2") {
        routeData = this.$router.resolve({
          path,
          query: {
            keyword: e.showTitle,
          },
        });
      } else {
        routeData = this.$router.resolve({
          path,
          query: {
            sourceID: e.sourceID,
            id: e.id,
            searchTitle: this.keyword,
            title,
          },
        });
      }
      window.open(routeData.href, "_blank");
    },
    setData(res, data) {
      this.seniorList = res.items;
      this.total = res.totalCount;
      this.PageIndex = 1;
      this.select = data.indexType;
      this.keyword = data.keyword;
      this.version = data.version;
      this.requestData = data;
    },
    onCurrentChange(index) {
      this.PageIndex = index;
      this.onSearch();
    },
    async onSearch() {
      try {
        this.loading = true;
        const res = await onSearch({
          ...this.requestData,
          indexType: this.select === "all" ? "" : this.select,
          version: this.version,
          keyword: this.keyword,
          PageIndex: this.PageIndex,
          PageSize: this.PageSize,
        });
        this.total = res.totalCount;
        this.seniorList = res.items;
        if (res.totalCount === 0) return this.$message.info("暂无数据");
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

.active {
  color: #edb122 !important;
}
.search-container {
  .content {
    min-height: calc(100vh - 300px);
    background: rgba(201, 199, 199, 0.1);
    padding-bottom: 50px;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    height: 100%;
    .left {
      // margin-left: 310px;
      width: 260px;
      .top {
        background: #fff;
        .title {
          text-align: center;
          line-height: 48px;
          width: 260px;
          height: 48px;
          background-color: #e0d0b6;
          font-size: 16px;
          color: #776259;
        }
        .province_title,
        .city_title,
        .village_title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #dd1524;
          height: 48px;
          padding: 0 10px;
        }
        .head:before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: #dd1524;
          vertical-align: middle;
          margin-right: 7px;
          border-radius: 4px;
        }
        .more {
          cursor: pointer;
          color: #776259;
        }
        .province,
        .city,
        .village {
          .name_box {
            scrollbar-width: none;
            -ms-overflow-style: none;
            padding-left: 20px;
            background: #faf7f3;
            font-size: 14px;
            .name {
              display: inline-block;
              cursor: pointer;
              line-height: 48px;
              color: #776259;
              padding-right: 15px;
            }
          }
        }
      }
      .bottom {
        margin-top: 40px;
        .title {
          text-align: center;
          line-height: 48px;
          width: 260px;
          height: 48px;
          background-color: #e0d0b6;
          font-size: 16px;
          color: #776259;
        }
        .list {
          li {
            cursor: pointer;
            line-height: 48px;
            text-align: center;
            color: #776259;
            border-bottom: 1px solid #e8e8e8;
          }
        }
      }
    }
    .right {
      width: 1000px;
      margin-left: 40px;
      .title {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        line-height: 48px;
        font-size: 16px;
        color: #776259;
        background: #e0d0b6;
        .info {
          font-size: 14px;
        }
      }
      .item {
        cursor: pointer;
        background: #fff;
        height: 160px;
        margin-top: 20px;
        padding: 20px 0 0 26px;

        .overstep {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .top {
          font-size: 16px;
          color: #000;
          font-weight: 700;
        }
        .middle {
          font-size: 14px;
          color: #696969;
          margin-top: 20px;
          line-height: 18px;
          padding-right: 10px;
          overflow: hidden; //溢出隐藏
          text-overflow: ellipsis; //省略号
          display: -webkit-box; //
          -webkit-box-orient: vertical; //设置弹性盒子的子元素的排列方式
          -webkit-line-clamp: 4; //设置显示文本的行数
          .publish {
            display: flex;
            justify-content: space-between;
            .press,
            .editor {
              width: 50%;
            }
          }
          .press {
            margin-left: 70px;
          }
          .edition {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            .press,
            .editor {
              width: 50%;
            }
          }
        }
        .bottom {
          font-size: 14px;
          color: #696969;
          margin-top: 26px;
        }
      }
      ::v-deep .paging {
        display: flex;
        justify-content: center;
        margin-top: 88px;
        li {
          width: 30px;
          height: 30px;
          background: #ececef;
          color: #2b2b2b;
        }
        li:not(.disabled).active {
          background: #3e74ae;
        }
      }
    }
  }
}
</style>
    